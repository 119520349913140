angular.module("app")
    .directive("cSpeed", function ($rootScope, _mapFieldService, _view, gettextCatalog, _logicService) {
        return {
            restrict: "E",
            template: "{{x}}<small>{{units}}</small>",
            scope: {
                value: '@value'
            },
            link: function ($scope) {
                if ($scope.value == 'n/a') {
                    $scope.x = gettextCatalog.getString('n/a');
                } else {
                    if (_view.getCurrentUser().units == "IMPERIAL") {
                        $scope.x = _logicService.round($scope.value * 2.236936, 2); //m/s to mph
                        $scope.units = gettextCatalog.getString('mph');
                    } else {
                        $scope.x = _logicService.round($scope.value, 2);
                        $scope.units = gettextCatalog.getString('m/s');
                    }
                }
            }
        }
    });